@import './reset.css';

* {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
}


body, html, #root {
  min-height: 100vh;
}


#root {
  display: flex;
  flex-direction: column;
 }

