/* header */


.logo_menu_animation:active {
  transform: scale(1.05);
  transition: all, 0.10s ease;

}


.header_menu_animation:hover {

  color: #FA8129;
  font-weight: 600;
  transition: all, 0.05s ease;

}



.header_menu_animation:active {
  color: #FA8129;
  font-weight: 600;
  transform: scale(1.10);
  transition: all, 0.10s ease;

}

.header_search_icon {
  width: 25px;
}


.header_search_icon:active {
  transform: scale(1.15);

}


.animation_burger_button:active {
  transform: scale(1.10);
  transition: all 0.05s ease;

}



.aniamtion_menu_burger:active {
  color: #FA8129;
  font-weight: 600;
  transform: scale(1.10);
  transition: all, 0.10s ease;
}

.animation_logo_arr:active {
  transform: scale(1.10);
  transition: all 0.05s ease;
}


/* footer */

.footer_icon_animation:hover {
  transform: scale(1.05);
  transition: all 0.05s ease;
}


.footer_icon_animation:active {
  transform: scale(1.15);
  transition: all 0.15s ease;
}


/*  */


.button_container {
  width: 593px;
  height: 116px;
  border: 1px solid #FA8129;

}



/* news button */

.animation_container {
  width: 500px;
  height: 100px;
  border: 1px solid #FA8129;
  overflow: hidden;
  transition: all 0.10s ease;


}


.animation_box {
  z-index: 0;
}


.circle_animation {

  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #FA8129;
  z-index: -1;
  position: relative;
  left: -185px;
  top: 85px

}


.animation_container:hover .circle_animation {
  animation-name: circle;
  animation-duration: 1.5s;

}



@keyframes circle {

  0% {
    scale: 1;

  }

  100% {
    scale: 100;
  }

}



/* MyButton */



.animation_my_button {
  border: 1px solid black;
  border-radius: 7px;
  color: black

}

.animation_my_button:hover {
  color: white;
  border: 1px solid rgba(0, 0, 0, 0);
  background-color: #FA8129;
  border-radius: 7px;
}


.animation_my_button:active {
  transform: scale(1.10);
  transition: all 0.10s ease;
}



/* modal page */


.modal_button_submit {
  background-color: #FEA633;
  color: white;
  font-weight: 600;
}


.modal_button_submit:active {
  transform: scale(1.05);
  transition: all 0.05s ease;
}



/* project */


.project_button_animation {

  color: white;
  border: 1px solid rgba(0, 0, 0, 0);
  background-color: #FA8129;
  border-radius: 10px;

}


.project_button_animation:hover {
  color: black;
  border: 1px solid rgb(0, 0, 0);
  background-color: #fea63300;
  border-radius: 10px;

}

.project_button_animation:active {
  transform: scale(1.05);
  transition: all 0.05s ease;
}



/* project card */


.project_card_animation:hover {
  transform: scale(1.10);
  transition: all 0.5s ease-in-out;

}


/* more_news */


.more_news_animation:active {
  transform: scale(1.10);
  transition: all 1s ease;
}




/* schedule */



.schedule_btn {
  width: 100%;
  background-color: #FA8129;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
}


.schedule_btn_checked {
  width: 100%;
  border-radius: 5px;
  background-color: #fb8f41;
}


.schedule_btn:hover {
  width: 181px;
  height: 51px;
  border-radius: 5px;
  background-color: #f9ab45;
}

.schedule_btn:active {
  transform: scale(1.05);
  transition: 0.05s ease all;
}


/* shelude */

.card {

  width: 90%;
  height: max-content;
  border-radius: 0px;
  border: 0px solid black;

}

.card_active {
  width: 90%;
  min-height: max-content;
  max-height: 100px;
  border-radius: 0px;
  border: 0px solid black;
  background-color: #FA8129;
  color: white;
}


.card_btn:hover {
  color: #FEA633;
  transition: all 0.05s ease;

}

.card_btn:active {
  transform:  scale(1.10);
  transition: all 0.05s ease;

}




/* scroll */

.scrollelem::-webkit-scrollbar {
  width: 15px;
}

.scrollelem::-webkit-scrollbar-track {
  background: #ffffff;
  padding: 10px;

}


.scrollelem::-webkit-scrollbar-thumb {
  background-color: #fea633;
  border-radius: 5px;
}


/* contact_icon */


.contacts_icon_aniamtion:hover {
  transform: scale(1.10);
  transition: all 1s ease;
}





/*  */




