.content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


.footer {
  flex: 0 0 auto;
}

